/* You can add global styles to this file, and also import other style files */
@import "../src/assets/theme/variables.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../src/assets/fonts/ibm/IBMPlexSans-Regular.eot');
	src: url('../src/assets/fonts/ibm/IBMPlexSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Regular.woff2') format('woff2'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Regular.woff') format('woff'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Regular.ttf') format('truetype'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Regular.svg#IBMPlexSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../src/assets/fonts/ibm/IBMPlexSans-Bold.eot');
	src: url('../src/assets/fonts/ibm/IBMPlexSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Bold.woff2') format('woff2'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Bold.woff') format('woff'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Bold.ttf') format('truetype'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Bold.svg#IBMPlexSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../src/assets/fonts/ibm/IBMPlexSans-SemiBold.eot');
	src: url('../src/assets/fonts/ibm/IBMPlexSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ibm/IBMPlexSans-SemiBold.woff2') format('woff2'),
		url('../src/assets/fonts/ibm/IBMPlexSans-SemiBold.woff') format('woff'),
		url('../src/assets/fonts/ibm/IBMPlexSans-SemiBold.ttf') format('truetype'),
		url('../src/assets/fonts/ibm/IBMPlexSans-SemiBold.svg#IBMPlexSans-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../src/assets/fonts/ibm/IBMPlexSans-Medium.eot');
	src: url('../src/assets/fonts/ibm/IBMPlexSans-Medium.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Medium.woff2') format('woff2'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Medium.woff') format('woff'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Medium.ttf') format('truetype'),
		url('../src/assets/fonts/ibm/IBMPlexSans-Medium.svg#IBMPlexSans-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

::-webkit-scrollbar-track {
	background-color: #7b7c7d;
}

::-webkit-scrollbar {
	width: 4px;
	background-color: #7b7c7d;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	background-color: #747373;
}

.modal {
	z-index: 1099;
}

.main-wrapper {
	min-height: 100%;
	position: relative;
	// padding-bottom: 52px !important;
}

* {
	padding: 0px;
	margin: 0px;
}

a,
.btn-link {
	&:hover {
		text-decoration: none;
	}
}


::placeholder {
	color: #ccc !important;
}

body {
	font-family: 'IBM Plex Sans';
	font-size: $font;
	color: $body-txt;
	// font-weight: 500;
	line-height: 1.5;
	text-align: left;
	background-color: $body-bg;
}

/* common css for all pages */
h1 {
	font-size: 24px;
}

h2 {
	font-size: 22px;
}

h3,
.h3 {
	font-size: 18px;
	font-weight: 600;
}

h4,
.h4 {
	font-size: 16px;
	font-weight: 600;
}

h5,
.h5 {
	font-size: 14px;
}

h6,
.h6 {
	font-size: 10px;
}

p {
	font-size: 16px;
}

a {
	font-size: 16px;
}

ul {
	padding-left: 0px;

	li {
		font-size: $font;
		list-style: none;
	}
}

::placeholder {
	font-size: calc($font - 2px);
}

.btn-sma {
	padding: 6px 10px !important;
	font-size: calc($font - 3px) !important;
}

.btn {

	font-size: calc($font - 4px);
	padding: 4px 8px;
	line-height: 14px;
	font-weight: 500;

	.ti-chevron-right {
		color: $primary;
	}


	&.apt-verified {
		border: 1px solid $light-green;
		color: $light-green;
		border-radius: 50px;
		background: $default;

		i {
			&::before {
				content: "\f1f8";
			}
		}

		&:hover {
			background: $light-green ;
			color: $default;

			i {
				color: $default;

			}
		}
	}


	&.apt-unverified {
		border: 1px solid $light-yellow;
		color: $light-yellow;
		border-radius: 50px;
		background: $default;

		i {
			//color: $light-yellow ;

			&::before {
				content: "\ea67";
			}
		}


		&:hover {
			border: 1px solid$light-yellow;
			background: $light-yellow;
			color: $default;

			i {
				color: $default;
			}
		}
	}

	&.apt-mapped {
		border: 1px solid $light-viloet;
		background: $default;
		color: $light-viloet;
		border-radius: 50px;

		i {

			&::before {
				content: "\eb9d";
			}
		}

		&:hover {
			border: 1px solid $light-viloet;
			background: $light-viloet;
			color: $default;
		}
	}

	&.apt-unmapped {
		border: 1px solid $light-gtone;
		background: $default;
		color: $light-gtone;
		border-radius: 50px;

		i {

			&::before {
				content: "\f1a6";
			}
		}

		&:hover {
			border: 1px solid $light-gtone;
			background: $light-gtone;
			color: $default;
		}
	}

	&.apt-pending {
		border: 1px solid $light-cyan;
		color: $light-cyan;
		border-radius: 50px;
		background: $default;

		i {

			&::before {
				content: "\f093";
			}
		}

		&:hover {
			background: $light-cyan ;
			color: $default;

			i {
				color: $default;
			}
		}
	}

	&.apt-approved {
		border: 1px solid $dark-green;
		color: $dark-green;
		border-radius: 50px;
		background: $default;

		i {

			&::before {
				content: "\eb3c";
			}
		}

		&:hover {
			background: $dark-green ;
			color: $default;

			i {
				color: $default;
			}
		}
	}

	&.apt-rejected {
		border: 1px solid $dark-red;
		color: $dark-red;
		border-radius: 50px;
		background: $default;

		i {

			&::before {
				content: "\ea2e";
			}
		}

		&:hover {
			background: $dark-red ;
			color: $default;

			i {
				color: $default !important;
			}
		}

	}

	&.apt-duplicate {
		border: 1px solid #390E5D;
		color: #390E5D;
		border-radius: 50px;
		background: $default;

		i {

			&::before {
				content: "\e944";
				font-family: "Feather" !important;
			}
		}

		&:hover {
			background: #390E5D;
			color: $default;

			i {
				color: $default !important;
			}
		}

	}

	&.apt-bul-det {
		border: 1px solid $primary;
		background: $primary;
		text-transform: uppercase;
		color: $default;
		padding: 4px;
		border-radius: 50%;
		position: absolute;
		right: 0px;
		bottom: 4px;
		transform: rotate(235deg);
		width: 24px;
		height: 24px;

		&:hover {
			border: 1px solid $primary;
			color: $primary;
			text-transform: uppercase;
			background: $default;
		}
	}

	&.btn-reset {
		color: $primary;
		background-color: rgba(45, 102, 216, 0.1);
		border-color: $primary;
		padding: 8px 12px;
		line-height: 1 !important;

		font-weight: 500;
		font-size: calc($font - 2px);
	}

	&.btn-icon {
		padding: 2px;
		font-size: 18px;
	}

	&.btn-primary {
		color: $default;
		background-color: $secondary;
		border-color: $secondary;
		padding: 8px 12px;
		line-height: 1 !important;

		font-weight: 500;
		font-size: calc($font - 2px);
	}

	&.btn-default {
		color: $default;
		border: 1px solid $border;
		background: #F2F4F5;
		color: $light-grey;
		padding: 8px 12px;
		line-height: 1 !important;

		font-weight: 500;
		font-size: calc($font - 2px);
	}

	&.btn-light {
		color: $default;
		border: 1px solid #0961ce;
		background: #dcebfe;
		color: #0961ce;
		padding: 8px 12px;
		line-height: 1 !important;

		font-weight: 500;
		font-size: 14px;
	}

	&.btn-danger {
		border: 1px solid #ffa7a1;
		background: #fde4e1;
		color: #f8594d;
		// border: 1px solid $border;
		// background: #F2F4F5;
		// color: #565D6A;
		padding: 8px 12px;
		line-height: 1 !important;

		font-weight: 500;
		font-size: 14px;
	}

	&.btn-secondary {
		color: $default;
		border: 1px solid $border;
		background: #F2F4F5;
		color: $light-grey;
		padding: 8px 12px;
		line-height: 1 !important;

		font-weight: 500;
		font-size: calc($font - 2px);
	}

	&.apt-no-latlg {
		color: #846701;
		background: rgb(226 186 50 / 59%);
		cursor: default;
		padding: 3px;
		display: inline-block;
	}

	&.btn-undo {

		color: $default;
		border: 1px solid $border;
		background: #34495e;
		padding: 8px 12px;
		line-height: 1 !important;

		font-weight: 500;
		font-size: calc($font - 2px);

		&:hover {
			background: #486480;
		}
	}

}

.btn-reload {
	position: absolute;
	color: #466f8c;
	background-color: rgba(70, 111, 140, 0.2);
	border-radius: 50%;
	top: -30px;
	right: 0px;
	padding: 0px !important;
	border: 1px solid #466f8c;
	width: 22px;
	height: 22px;

	&:hover {
		color: #fff;
		background-color: #466f8c;

	}
}

.btn:focus {
	outline: none;
	box-shadow: none;
}

.apt-mapaction-n {
	display: inline-block;
}

.badge {
	padding: 5px 6px;
	font-size: calc($font - 7px);
	text-transform: uppercase;
	border-radius: 50px;

	&.apt-mapped {
		border: 1px solid #bab1fd;
		background: #bab1fd;
		color: $dark-blue;
	}

	&.apt-unmapped {
		border: 1px solid #fdefa2;
		background: #fdefa2;
		color: $dark-blue;
	}

	&.apt-unverified {

		border: 1px solid $light-red;
		color: $default;
		background: $light-red;

	}

	&.apt-approved {
		border: 1px solid $light-green;
		background: $light-green;
		color: $default;
	}

	&.apt-rejected {
		border: 1px solid $dark-red;
		background: $dark-red;
		color: $default;
	}

	&.apt-waitingapproval {
		border: 1px solid $light-cyan;
		background: $light-cyan;
		color: $default;
	}

}

.apt-form {
	.form-group {
		label {
			font-size: calc($font - 4px);
			font-weight: 600;
			color: $form-label-color;
			text-transform: uppercase;

			sup {
				color: $dark-red;
				font-size: calc($font - 1px);
				top: 0;
				left: 2px;
			}
		}

		select {
			border-top-right-radius: 4px !important;
			border-bottom-right-radius: 4px !important;
		}

		.form-control {
			font-size: calc($font - 2px);
			display: block;
			padding: 0.375rem 0.75rem;
			font-weight: 400;
			line-height: 1.5;
			color: $dark;
			background-color: $default;
			border: 1px solid $form-control-border;
			box-shadow: none;
			// padding-right: 0px;
			height: 36px;

			&:disabled {
				background: #f3f3f3 !important;
			}
		}

		.ng-select {
			.ng-select-container {
				color: $dark;
				background-color: $default;
				border: 1px solid $form-control-border;
				// max-height: 100px;
				// overflow-y: auto;
			}

			&.ng-select-scroll {
				.ng-select-container {

					.ng-value-container {
						height: 60px;
						overflow-y: auto;
					}
				}
			}
		}

		.input-group {
			.input-group-text {
				border: 1px solid $form-control-border;
			}

			.input-group-prepend {
				.input-group-text {
					background: none;
					border: 1px solid $form-control-border;
					border-right: 0px;


					i {
						color: $form-label-color;
					}
				}
			}


			.ng-select {
				width: calc(100% - 40px);

				.ng-select-container {
					// border-left: none;
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
				}
			}

			.input-loader {
				flex-grow: 1;

				.form-control {
					border-bottom-left-radius: 0px;
					border-top-left-radius: 0px;
				}
			}

		}

		textarea {
			height: auto !important;
		}

		&.f-select {
			position: relative;

			.fe-chevron-down {
				position: absolute;
				right: 12px;
				top: 10px;
				color: $form-control-border;
			}
		}
	}

	.form-control {
		box-shadow: none !important;
		font-size: $font;

		&.form-select {
			-webkit-appearance: none;
			padding-right: 30px;
		}
	}

	.btn-outline-default {
		background: #f7f9fd;
		color: $body-txt;
		min-width: 100px;
	}

	.btn-outline-primary {
		background: $primary;
		color: $default;
		min-width: 100px;
	}
}

.ng-select {
	&.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
		// padding-top: 0px;
		padding-left: 8px;
	}

	&.ng-select-single .ng-select-container .ng-value-container .ng-value {
		font-size: calc($font - 2px);
	}

	&.ng-select-disabled .ng-select-container {
		background-color: #f3f3f3 !important;
	}

	.ng-select-container {
		.ng-arrow-wrapper {
			.ng-arrow {
				border: 0;
				height: 20px;
				width: 20px;
				display: inline-block;
				position: absolute;
				right: 5px;
				top: -11px;
				text-align: right;

				&:before {
					content: "\e92e";
					font-family: "Feather" !important;
					color: $light-grey;
					font-size: calc($font - 1px);
				}
			}
		}

		.ng-value-container {
			min-height: calc($form-control-het - 10px);
			position: relative;


			.ng-placeholder {
				font-size: calc($font - 2px);
				color: #c3c3c3;
				position: relative;
				line-height: 24px;
			}
		}

		.ng-clear-wrapper {
			margin-right: 8px;
		}
	}

	// &.ng-select-opened {
	// 	.ng-select-container {
	// 		.ng-value-container {
	// 			.ng-placeholder {
	// 				// top: 5px;
	// 			}
	// 		}
	// 	}
	// }

	.ng-dropdown-panel {
		text-align: left;
	}
}




.dropdown {
	.dropdown-toggle {
		position: relative;
		padding-right: 20px;
		color: $dark;
		display: flex;
		align-items: center;

		&:before {
			content: "\e92e";
			font-family: "Feather" !important;
			position: absolute;
			right: 0px;
			color: #acacac;
		}

		&:after {
			display: none;
		}
	}

	.dropdown-menu {
		padding: 0px;
		margin: 0px;
		min-width: 160px;

		i {
			color: $primary;
		}

		a {
			padding: 8px 15px !important;
			font-weight: 400;
			border-bottom: 1px solid rgb(219 230 235 / 49%);

			&:last-of-type {
				border-bottom: none;
			}

			&:hover {
				&:after {
					background: none !important;
				}
			}

			&:active {
				color: #16181b;
				text-decoration: none;
				background-color: #e9ecef;
			}
		}

		&::before {
			position: absolute;
			top: -8px;
			right: 20px;
			content: "";
			width: 0;
			height: 0;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 8px solid #C2C3C4;
		}

		&:after {
			position: absolute;
			top: -7px;
			right: 22px;
			content: "";
			width: 0;
			height: 0;
			border-left: 7px solid transparent;
			border-right: 6px solid transparent;
			border-bottom: 7px solid $default;
		}
	}
}


.apt-loader {
	position: fixed;
	background: rgba(255, 255, 255, 0.76);
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;


}

.triangles {
	transform: translate(-50%, -50%);
	height: 81px;
	width: 90px;
	position: absolute;
	left: 50%;
	top: 50%;

	.tri {
		position: absolute;
		animation: pulse_51 750ms ease-in infinite;
		border-top: 27px solid #0761CE;
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		border-bottom: 0px;

		&.invert {
			border-top: 0px;
			border-bottom: 27px solid #0761CE;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
		}

		&:nth-child(1) {
			left: 30px;
		}

		&:nth-child(2) {
			left: 15px;
			top: 27px;
			animation-delay: -125ms;
		}

		&:nth-child(3) {
			left: 30px;
			top: 27px;
		}

		&:nth-child(4) {
			left: 45px;
			top: 27px;
			animation-delay: -625ms;
		}

		&:nth-child(5) {
			top: 54px;
			animation-delay: -250ms;
		}

		&:nth-child(6) {
			top: 54px;
			left: 15px;
			animation-delay: -250ms;
		}

		&:nth-child(7) {
			top: 54px;
			left: 30px;
			animation-delay: -375ms;
		}

		&:nth-child(8) {
			top: 54px;
			left: 45px;
			animation-delay: -500ms;
		}

		&:nth-child(9) {
			top: 54px;
			left: 60px;
			animation-delay: -500ms;
		}
	}
}

@keyframes pulse_51 {
	0% {
		opacity: 1;
	}

	16.666% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

// No Data
.no-data-block {
	position: relative;
	min-height: 50vh;
	width: 100%;

	.no-data-block-inner {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		height: 100%;
		width: 100%;
		margin: auto;
		z-index: 99;

		.no-data-block-img {
			position: relative;
			height: 145px;

			img {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}

		p {
			font-size: calc($font - 3px);
			;
			text-align: center;
			margin: 0;
			margin-top: 3px;
			color: #8a96a3;
			font-weight: 600;
		}
	}
}

.breadcrumb {
	margin-bottom: 0rem;
	padding-bottom: 8px;
	background: none;


	.breadcrumb-item {
		font-size: calc($font - 2px);
		color: $light-grey;

		&+.breadcrumb-item::before {
			float: left;
			padding-right: 0.5rem;
			color: $light-grey;
			content: "\e930";
			font-family: 'Feather' !important;
			font-size: calc($font - 2px);
		}

		&.active {
			color: $dark-blue;
			font-weight: 600;
		}
	}

	a {
		color: $light-grey;
		font-size: calc($font - 2px);

		&:hover {
			color: $primary;
		}

	}
}

// Modal Form Design - Start
.modal-dialog {
	.modal-content {
		border: 1px solid #D9E8F1;
		.modal-header {
			position: relative;
			border-bottom: 0;
			color: $light-dark;
			font-size: 20px;
			padding: 15px 10px 6px;
			text-align: center;
    		justify-content: center;
			.btn-close {
				position: absolute;
				font-size: calc($font);
				top: 10px;
				font-size: 12px;
				padding: 0px;
				right: 10px;
				color: #F75151;
				margin: 0;
				&:before {
					content: "\ea02";
					font-family: "Feather" !important;
					color: #F75151;
					font-size: calc($font);
				}

				.visually-hidden {
					display: none;
				}

				&.close {
					opacity: 0.1;
					font-weight: 400;
					&:hover {
						opacity: 1;
					}
				}
			}
		}
		&:hover {
			.close {
				opacity: 1 !important;
			}
		}
		.modal-footer>* {
			margin: 0px;
		}

		.modal-footer {
			background: #fff;
			border: 0;
			padding: 0 0 15px 0;
		}

		.modal-body {
			padding: 10px;
			.per-badge {
				color: #1C2D3A;
				background-color: #E7EFFD;
				border: 1px solid #B7C9EA;
				padding: 0.3rem 0.7rem;
				font-size: 12px;
				font-weight: 600;
				margin-right: 12px;
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.apt-modify-mode {
				text-align: center;
				.form-group {
					margin-top: 20px;
					margin-bottom: 30px;
				}
				h4 {
					color: $light-cyan;
				}
				h5 {
					color: $light-cyan;
					border: 1px solid;
					padding: 6px 12px;
					border-radius: 20px;
					background: #ecf9fd;
				}
				p {
					color: $light-grey;
					margin-top: 10px;
					margin-bottom: 20px;
					b {
						color: $light-gtone;
					}
				}
				.btn {
					min-width: 75px;
					&.btn-primary {
						border: 1px solid #0761CE;
    					height: 36px;
					}
				}
			}
		}

		.modal-footer {}
	}
}

// Modal Form Design - End

.apt-complist-w-100 {
	width: calc(100% - 8px) !important;

	ul {
		li {
			margin-bottom: 8px;

			.bu-counts {
				margin-left: 15px;
			}
		}
	}

	.card.apt-listbx .card-body .apt-loc h5 {
		width: 100% !important;
	}

}

.apt-mapg {
	width: calc(100% - 500px);

	.custom-menu {
		right: 510px !important;

		.btn-danger {
			padding: 12px !important;
		}
	}
}

.apt-maponly {
	width: calc(100% - 0px);
}

.apt-complist {
	background: $default;
	height: calc(100% - 0px);
	width: 500px;
	z-index: 99;
	border-radius: 0 4px 4px 0;
	background: $default;
	position: relative;

	.apt-head {
		padding: 0px 16px;
		position: relative;
		box-shadow: 1px 4px 2px -3px rgba(219, 230, 235, 0.66);
		z-index: 1;
	}

	.apt-comtitle {
		p {
			color: #7D8689;
			font-size: calc($font - 2px);
		}

		h3,
		p {
			font-weight: 600;

			span {
				color: $primary;
			}
		}

	}

	.apt-comsortby {
		p {
			color: $light-grey;
			font-size: calc($font - 2px);
		}

		.ng-select .ng-select-container {
			border: 1px solid rgba(219, 230, 235, 0.5);
			border-bottom-left-radius: 0px;
			border-top-left-radius: 0px;
			border-left: none;
			color: $primary;
			min-width: 134px;
			cursor: pointer;

			&:hover {
				box-shadow: none;
			}
		}

		.btn-sm {
			padding: 4px 8px;
			font-size: calc($font - 2px);
		}
	}

	.apt-compbx {
		list-style: none;
		display: flex;
		height: 100%;
		width: 100%;
		flex-wrap: wrap;
		margin-bottom: 0px;

		li {
			width: calc(50% - 15px);
			display: inline-block;
			margin-right: 15px;
			margin-bottom: 15px;
			flex-grow: 1;
		}

		li:nth-child(even) {
			margin-right: 0px;
		}
	}

	.pagination {
		float: right;
		margin-bottom: 0px;
	}

	// &:after {
	//  content: "";
	//  height: 100%;
	//  width: 6px;
	//  position: absolute;
	//  right: -1px;
	//  top: 0;
	//  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.25);
	//  z-index: 1;
	//  background-color: $default;
	// }
}


.apt-title {
	.apt-address {
		p {
			font-size: 13px;
			padding-left: 8px;
		}
	}

	.fe-map-pin {
		color: $dark-red;
		width: 18px;
		height: 18px;
		line-height: 18px;
		border-radius: 100px;
		padding: 0px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	}
}

.apt-detail {
	.apt-build-card {
		margin-bottom: 15px;

		.card {
			position: relative;
			border: 1px solid $border;

			&:hover {
				border-color: #C2C3C4;
				cursor: pointer;
			}
		}

		.apt-country {
			border: 1px solid $border;
			border-radius: 4px;
			padding: 5px;
			position: absolute;
			right: 16px;
			top: 16px;
			font-size: 13px;
			color: $primary;
		}

		h5 {
			margin-bottom: 0px;
			color: #1C2D3A !important;
			padding-left: 12px;
			font-size: 16px;
		}

		.apt-icon {
			color: $primary;
			background: #E5E9EC;
			width: 36px;
			height: 36px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;

			i {
				font-size: 24px;
			}
		}

		ul {
			margin-top: 12px;
			margin-bottom: 0px;

			li {
				list-style: none;
				font-size: 14px;
				padding: 8px 0px;
				border-bottom: 1px solid $border;
				display: flex;
				text-transform: capitalize;
				font-weight: 500;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				span {
					padding-left: 20px;
					font-weight: 400;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}

	.apt-build-head {
		h3 {
			color: #1C2D3A;
			display: inline;
		}

		.badge-primary {
			color: $primary;
			background-color: $primary;
			margin-left: 12px;
		}

		.apt-unit-img {
			// flex-direction: row;
			// height: 250px;
			// overflow: hidden;

			.apt-desc {
				// width: 300px;
				// margin-left: 10px;
			}

			h4 {
				font-weight: 400;
				margin-bottom: 0px;
				padding-left: 12px;

			}

			img {
				width: 280px;
				border-radius: 4px;
			}
		}

		h5 {
			color: #878787;
		}
	}

	p {
		color: #878787;
	}

	.apt-tabm {
		padding-top: 20px;

		.nav {
			border-bottom: 2px solid #E5E9EC;

			.nav-item {
				margin-right: 16px;

				&:last-of-type {
					margin-right: 0px;
				}

				&.active {
					.nav-link {
						color: $primary;
						background-color: $default;
						border: none;
						border-bottom: 2px solid $primary;
					}
				}
			}





			.nav-link {
				padding: .5rem;

				border: none;
				color: #000;

				&:hover {
					color: $primary;
					background-color: $default;

					border-bottom: 2px solid $primary;

				}
			}

		}
	}

	.apt-info {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		li {
			flex: 0 0 33%;
			max-width: 33%;
			list-style: none;
			margin-top: 16px;
			color: #878787;
			position: relative;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			// &::after{
			// 	content: "\f698";
			// 	position: absolute;
			// 	left:0px;
			// 	top:0px;
			// 	color: #878787;
			// 	font-family: "tabler-icons" !important;
			// }

		}
	}


	.tab-style-2 {
		.nav-tabs {
			border: none;
			background: #F4F7FD;

			.nav-link.active {
				color: $primary !important;
				background-color: #DFE7F9 !important;
				border: none !important;
			}

			.nav-link {
				padding: .5rem;
				border: none;
				color: #000;
				border-radius: .25rem;
				margin-right: 16px;
				min-width: 60px;
				text-align: center;

				&:last-of-type {
					margin-right: 0px;
				}

				&:hover {
					color: $primary;
					background-color: #DFE7F9;
					border: none;
				}
			}

		}

		.tab-content {
			padding: 10px 0px;

			.card {
				margin-bottom: 16px;
				border-color: #E3E7EA;

				&:last-of-type {
					margin-bottom: 0px;
				}

				.table {
					thead {
						th {
							background: #F5F6F8;
							border: none;
						}
					}

					tbody {
						td {
							border-color: #F5F6F8;
						}
					}
				}
			}
		}


	}
}

.error-has {
	font-size: calc($font - 2px);
}

.bar-chart-1 {
	.highcharts-figure {
		margin-bottom: 0px !important;

		.highcharts-label.highcharts-data-label.highcharts-data-label-color-0 {
			display: none;
		}

		.highcharts-label.highcharts-data-label.highcharts-data-label-color-1 {
			display: none;
		}

		.highcharts-label.highcharts-data-label.highcharts-data-label-color-2 {
			display: none;
		}

		.highcharts-label.highcharts-data-label.highcharts-data-label-color-3 {
			display: none;
		}

		.highcharts-label.highcharts-data-label.highcharts-data-label-color-4 {
			display: none;
		}

		.highcharts-label.highcharts-data-label.highcharts-data-label-color-5 {
			display: none;
		}

		.highcharts-label.highcharts-data-label.highcharts-data-label-color-6 {
			display: none;
		}

		.highcharts-axis-labels.highcharts-xaxis-labels {
			font-weight: 600;
			fill: #000 !important;
			font-size: 12px !important;
			font-family: 'IBM Plex Sans', sans-serif;
		}

		.highcharts-credits {
			display: none;
		}

		.highcharts-title {
			display: none;
		}

		.highcharts-axis-line {
			stroke: #ededed;
		}

		.highcharts-axis-title {
			font-weight: 600;
			fill: #000 !important;
			font-size: 11px !important;
			font-family: 'IBM Plex Sans', sans-serif;
		}

		.highcharts-point.highcharts-color-0 {
			fill: #D6E1F8;
		}

		.highcharts-point.highcharts-color-1 {
			fill: #D6E1F8;

			&:hover {
				fill: #0761CE;
			}
		}

		.highcharts-point.highcharts-color-2 {
			fill: #D6E1F8;
		}

		.highcharts-point.highcharts-color-3 {
			fill: #D6E1F8;
		}

		.highcharts-point.highcharts-color-4 {
			fill: #D6E1F8;
		}

		.highcharts-point.highcharts-color-5 {
			fill: #D6E1F8;
		}

		.highcharts-point.highcharts-color-6 {
			fill: #D6E1F8;
		}

	}
}

.bar-chart-2 {
	.highcharts-figure {
		margin-bottom: 0px !important;

		.highcharts-title {
			display: none;
		}

		.highcharts-credits {
			display: none;
		}

		.highcharts-axis-title {
			font-weight: 600;
			fill: #000 !important;
			font-size: 11px !important;
			font-family: 'IBM Plex Sans', sans-serif;
		}

		.highcharts-axis-labels.highcharts-yaxis-labels {
			font-weight: 600;
			fill: #000 !important;
			font-size: 11px !important;
			font-family: 'IBM Plex Sans', sans-serif;
		}

		.highcharts-axis-line {
			stroke: #ededed;
		}

		.highcharts-axis-labels.highcharts-xaxis-labels {
			font-weight: 600;
			fill: #878787 !important;
			font-size: 12px !important;
			font-family: 'IBM Plex Sans', sans-serif;
		}

		.highcharts-axis-labels.highcharts-yaxis-labels {
			text {
				color: #878787 !important;
			}
		}

		.highcharts-point.highcharts-color-0 {
			fill: #C8CDFF;

		}

		.highcharts-point.highcharts-color-1 {
			fill: #8594EE;
		}

		.highcharts-point.highcharts-color-2 {
			fill: #5B6AD0;
		}

		.highcharts-legend-item.highcharts-column-series.highcharts-color-0.highcharts-series-0 {
			font-family: 'IBM Plex Sans', sans-serif;
			font-size: 12px;

			.highcharts-point {
				fill: #c7cdff;
			}
		}

		.highcharts-legend-item.highcharts-column-series.highcharts-color-1.highcharts-series-1 {
			font-family: 'IBM Plex Sans', sans-serif;
			font-size: 12px;

			.highcharts-point {
				fill: #8595ee
			}
		}

		.highcharts-legend-item.highcharts-column-series.highcharts-color-2.highcharts-series-2 {
			font-family: 'IBM Plex Sans', sans-serif;
			font-size: 12px;

			.highcharts-point {
				fill: #5b6bd0;
			}
		}
	}
}

.ng-dropdown-panel {
	.ng-dropdown-panel-items {
		.ng-option {
			font-size: 14px;
			border-bottom: 1px solid #edeced;
			color: #7D8689;
			text-align: left;

			&:last-child {
				border-bottom: 0px;
			}

			&.ng-option-marked {
				background-color: $default;
				color: $primary;
				font-weight: 500;
			}
		}
	}
}



///--- Apartment Table start///---
.apt-table {
	// height: calc(100vh - 115px) !important;
	padding: 15px;

	.apt-roles-line {
		position: relative;

		&::after {
			content: "";
			height: 100%;
			width: 1px;
			position: absolute;
			top: 0px;
			right: 0px;
			background-color: #9BB8C3;

		}
	}

	.apt-card-title {
		.card-user-head-rght {
			.input-group {
				width: 280px;
				margin-right: 12px;

				.form-control {
					background-color: #edeff1;
					border: 1px solid #edeff1;

					&:focus {
						box-shadow: none;
					}
				}
			}

			.apt-srch-icon {
				background: #edeff1;
				border-color: #edeff1;
				color: #859CA8;
			}
		}

		.card-head-rght {
			.input-group {
				width: 432px;

				.form-control {
					background-color: #edeff1;
					border: 1px solid #edeff1;

					&:focus {
						box-shadow: none;
					}
				}
			}

			.apt-srch-icon {
				background: #edeff1;
				border-color: #edeff1;
				color: #859CA8;
			}
		}

		button {
			&.btn-primary {
				color: $default;
				background-color: $secondary;
				border-color: $secondary;
				padding: 8px 12px;
				line-height: 1;

			}

			i {
				font-size: 18px;
			}
		}

		h5 {
			font-size: 18px;
			color: $primary;
			margin-bottom: 0px;
		}
	}
}




.apt-table-card {
	// height: calc(100vh - 210px) !important;
	border: 1px solid #9AB8C2;
	border-radius: 4px;
	height: auto;
	// padding: 12px 15px;
	// overflow-y: auto;


	table {
		border: #9BB8C3;

		thead {
			background: #F5F6F8;
			border-radius: 6px 6px 0px 0px;
			position: sticky;
			top: 0;
			z-index: 1;


			tr {
				th {
					color: #1C2D3A;
					font-size: 16px;
					font-weight: 500;
					padding: 8px 10px;
					border-bottom: 0px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 80px;
					font-size: 15px;
					border-top: 0px;

					&.apt-head-unverified {
						background-color: #F5E4F2;
						max-width: 150px;

						&:hover {
							background: #f4d9ef !important;
						}
					}

					&.apt-total-count {
						max-width: 150px;
					}

					&.apt-verified {
						max-width: 150px;
					}

				}
			}
		}

		tbody {

			tr {
				border: 1px solid #F5F6F8;

				td {
					padding: 8px 10px !important;
					color: $light-grey;
					border-top: 0px;
					// white-space: nowrap;
					// overflow: hidden;
					// text-overflow: ellipsis;
					font-size: 15px;

					&.apt-body-unverified {
						background-color: #F5E4F2;
					}


					&.apt-task-assign {
						span {
							color: $primary;
							font-weight: 500;
						}

						max-width: 160px;
						// display: flex;
						// flex-direction: column;
					}

					&.apt-task-location {
						max-width: 100px;
					}

					&.apt-task-title {
						color: $primary;
						font-weight: 500;

						i,
						span {
							color: $light-grey;
						}

					}

					&:first-of-type {
						min-width: 80px;
					}

					&.apt-table-td-per {

						// max-width: 180px;
						span {
							&.badge-primary {
								color: #1C2D3A;
								background-color: #E7EFFD;
								border: 1px solid #B7C9EA;
								padding: .3rem .7rem;
								font-size: 12px;
								font-weight: 600;
								margin-right: 12px;
								margin-top: 6px;
								margin-bottom: 6px;

							}
						}
					}

					&.apt-table-td-icon {

						// float: right;
						i {
							font-size: 18px;
						}

						.apt-table-edit {
							color: $primary;
							cursor: pointer;
						}

						.apt-table-user {
							color: #147114;
							cursor: pointer;
						}

						.apt-table-user-2 {
							color: #D82D34;
							cursor: pointer;
						}
					}
				}

			}
		}
	}
}


///--- Apartment Table end///---

// sp 13 apt list
.card {
	height: 100%;

	&.apt-listbx {
		border: 1px solid $border;
		// margin-bottom: 16px;
		padding: 10px;
		position: relative;
		.copy-btn{
			visibility: hidden;
		}

		

		.apt-notmarked {
			position: absolute;
			bottom: -8px;
			left: 35%;
			background-color: $primary;
			color: $default;
		}

		&.apt-no-latlong {
			.card-body {
				padding: 0px;

				.apt-loc {
					h5 {
						.ti-map-pin {
							&::before {
								content: "\ecf3";
								font-family: "tabler-icons";
								// color:$light-red;
							}
						}
					}
				}
			}
		}

		&.apt-highlight {
			border: 1px solid #7f00ff !important;
		}

		&.apt-no-latlong {
			.card-body {
				padding: 0px;

				.apt-loc {
					h5 {
						.ti-map-pin {
							&::before {
								content: "\ecf3";
								
								font-family: "tabler-icons";
								color: #fb7f7e;
								// color:$light-red;
							}
						}

						// border-bottom: 1px dashed $light-red;

					}
				}
			}
		}

		.card-body {
			padding: 0px;
			position: relative;
			z-index: 1;

			.apt-loc {
				margin-bottom: 10px;
				min-height: 41px;

				h3 {
					font-size: calc($font - 2px);
					color: $dark-blue;
					// overflow: hidden;
					// text-overflow: ellipsis;
					// white-space: nowrap;
					// width: 242px;
					display: flex;
				}

				h5 {
					font-size: calc($font - 2px);
					color: $dark-grey;
					margin: 0px;
					margin-top: 13px;
					display: flex;
					align-items: center;

					p {
						font-size: calc($font - 2px);
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						width: 280px;
					}
				}

			}

			.apt-action {
				position: absolute;
				right: 70px;
				top: 2px;
				display: flex;
				z-index: 1;



			}

			.bu-counts {
				.apt-icon {
					color: $primary;
					background: #E5E9EC;
					width: 36px;
					height: 36px;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;

					i {
						font-size: calc($font + 8px);
						border-radius: 100px;
					}
				}

				&:first-of-type {
					margin-right: 16px;
				}

				.bu-count {
					margin-left: 16px;

					span {
						display: block;

						&:last-of-type {
							color: $dark-blue;
							font-size: calc($font - 0px);
							font-weight: 600;
						}

						&:first-of-type {
							color: $dark-grey;
							font-size: calc($font - 2px);
							font-weight: 600;
						}
					}
				}
			}

			.apt-bul-det {
				visibility: hidden;
			}



		}

		.card-title {
			border-bottom: 1px solid #E5E9EC;
		}

		.card-values {
			display: flex;
			flex-wrap: nowrap;

			li {
				width: 100%;
				flex-grow: 1;
				margin-bottom: 10px;
				position: relative;
				align-items: center;

				&.apt-tags {
					flex-shrink: 1.5;
				}
			}
		}

		&:hover {
			border-color: $primary;
			cursor: pointer;
			.copy-btn{
				visibility: visible;
			}

			.card-title {
				border-color: #C2C3C4;
			}

			h3 {
				color: $primary;
			}

			.bu-count {
				margin-left: 16px;

				span {
					display: block;

					&:last-of-type {
						color: $primary !important;
					}
				}
			}

			.card-body {
				.apt-bul-det {
					visibility: visible;
				}
			}
			.copy-btn{
				visibility: visible;
			}
			
		}
	}
}

// provider-info

.apt-provider {
	background: #FFF4DB;
	height: 40px;
	width: 100px;
	border-top-left-radius: 90px;
	border-top-right-radius: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	img {
		width: 60px;
	}

	span {
		width: 28px;
		height: 28px;
		background: $default;
		line-height: 26px;
		text-align: center;
		border-radius: 50%;
		border: 2px solid #fff4db;
		position: absolute;
		top: -14px;
		left: -5px;
	}

}

.apt-buildingc {

	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.apt-create-btn {
		background: #f5f6f8;
		height: 40px;
		min-width: 100px;
		border-top-left-radius: 0px;
		border-top-right-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 0px 4px;

		h4 {
			color: $dark-blue;
			margin-bottom: 0px;
		}



		.btn {
			width: 24px;
			height: 24px;
			padding: 0px;
			margin-left: 8px;
			border-radius: 50%;
			// position: absolute;
			// right: -7px;
			// top: -15px;
		}
	}


	span {
		// min-width: 24px;
		// height: 24px;
		// background: $primary;
		// line-height: 20px;
		// text-align: center;
		// border-radius:4px;
		// border: 2px solid $primary;
		// display: inline-block;
		// color: $default;

	}

}



// phase info

.apt-phaseinfo {
	// border-bottom: 1px solid $border;
	// padding-bottom: 16px;
	// margin-bottom: 16px;
	position: relative;
	min-height: 42px;

	h3 {
		font-size: calc($font - 2px);
		margin-bottom: 8px !important;
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
		// width: 220px;
		display: flex;
		cursor: pointer;
		.copy-btn{
			visibility: hidden;
		}
		&:hover{
			.copy-btn{
			visibility: visible;
			}
		}
	}

	.apt-phaseinfo-location {
		color: #7D8689;
		font-size: calc($font - 2px);
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
		// width: 150px;
		display:flex;
		cursor: pointer;
		.copy-btn{
			visibility: hidden;
		}
		&:hover{
			.copy-btn{
				visibility: visible;
				}
		}

		i {
			color: #0E263C;
			margin-right: 5px;
		}

		&.apt-no-latlong {
			i {
				&::before {
					content: "\ecf3";
					color: #fb7f7e;
				}
			}
		}
	}

	.apt-loc {
		margin-bottom: 10px;

		h3 {
			font-size: calc($font + 0px);
			color: $dark-blue;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-bottom: 0px !important;
		}

		h5 {
			font-size: calc($font - 2px);
			color: $dark-grey;
			margin: 0px;
			display: flex;
			align-items: center;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			i {
				color: $dark-blue;
				margin-right: 8px;
			}

			p {
				font-size: calc($font - 2px);
			}
		}

	}

	.apt-action {
		position: absolute;
		right: 0px;
		top: -38px;
		padding-right: 38px;

		.btn {
			margin-left: 8px;

			&:first-of-type {
				margin-left: 0px;
			}
		}

		.btn {

			&.apt-verified {
				color: $default;
				background: $light-green ;
				cursor: default;
			}

			&.apt-rejected {
				background: $dark-red;
				color: $default;
				border-radius: 50px;
				cursor: default;
			}

			&.apt-pending {
				color: $default;
				background: $light-cyan;
				cursor: default;
			}

			&.apt-unverified {
				color: $default;
				background: $light-yellow;
				cursor: default;

				i {
					color: $default;
				}
			}
		}
	}


}

// .apt-phasem {
// 	height: calc(100vh - 268px);
// 	overflow-y: auto;
// }

.apt-phaselist {
	// height: calc(100vh - 479px);
	// overflow-y: auto;
	margin-bottom: 16px;
}

.apt-phasecard {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0px;

	li {
		width: calc(34% - 16px);
		display: inline-block;
		margin-right: 16px;
		margin-bottom: 16px;

		.card {
			border: 1px solid $border;

			.card-header {
				background: #F5F6F8;
				padding: 10px;
				position: relative;
				min-height: 37px;

				h5 {
					margin-bottom: 0px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 100%;
				}

				.apt-mapaction {
					position: absolute;
					top: 50%;
					right: 4px;
					transform: translate(0%, -50%);

					.btn {
						// border-top-right-radius: 0px;
						// border-bottom-right-radius: 0px;

					}

				}


			}

			.card-body {

				padding: 8px;
				position: relative;

				h3 {
					color: $dark-blue;
					text-align: center;
				}

				h5 {
					color: $dark-grey;
					text-align: center;
				}

				// .apt-paction {
				// 	position: absolute;
				// 	top: 6px;
				// 	right: 6px;
				// 	display: flex;
				// 	flex-direction: column;
				// 	// transform: translate(0%, -50%);
				// 	// visibility: hidden;

				// 	.btn {
				// 		margin-bottom: 8px;
				// 		&:last-of-type{
				// 			margin-bottom: 0px;
				// 		}
				// 	}
				// }
				// .apt-maction{
				// 	position: absolute;
				// 	left: 0px;
				// 	bottom: 0px;
				// 	.badge{
				// 		border-radius: 0px;
				// 		font-size:calc($font - 8px);
				// 		padding: 4px;
				// 		border-bottom-left-radius: 2px;
				// 		border-top-right-radius: 4px;
				// 	}
				// }
			}

			&.apt-building-highlight {
				border: 1px solid $primary;
			}

			&.apt-phasead {
				display: flex;
				align-items: center;
				justify-content: center;
				background: #FBFAFF;
				border: 1px dashed $primary;

				.apt-ad {
					background: #CEDAF7;
					color: $primary;
					width: 60px;
					height: 60px;
					line-height: 60px;
					border-radius: 50%;
					text-align: center;
					font-size: calc($font + 10px);
				}

			}

		}


		&:nth-child(3n+3) {
			margin-right: 0px;
		}

		&:hover {
			.card {
				border-color: $primary;
				cursor: pointer;

				.card-header {
					h5 {
						color: $primary;
					}

					// .apt-paction {
					// 	visibility: visible;
					// }
				}

				.card-body {
					h3 {
						color: $primary;
					}
				}
			}

		}

	}

}

// name history

.apt-namehisty {
	h3 {
		background: #F5F6F8;
		color: $primary;
		padding: 10px;
		font-size: calc($font - 0px);
		border-radius: 6px;
		margin-top: 16px;
	}

	.apt-nmhlist {
		// max-height: 220px;
		max-height: 100px;
		overflow-y: auto;

		ul {
			list-style: none;
			padding: 0;
			margin-bottom: 0px;
		}

		li {
			padding-left: 20px;
			position: relative;
			margin-bottom: 8px;
			border-bottom: 1px solid #f2f2f2;
			padding-bottom: 8px;
			margin-top: 8px;

			.apt-hstatus {
				color: $primary;
				font-size: calc($font - 2px);
				position: relative;
				padding-left: 8px;
				padding-right: 8px;

				&::before {
					content: "";
					position: absolute;
					left: 0px;
					top: 0px;
					width: 1px;
					height: 100%;
					background: $border;
				}

				.apt-dup {
					font-size: calc($font - 4px);
				}
			}

			.apt-hdate {
				color: #7D8689;
				font-size: calc($font - 2px);
				position: relative;
				// padding-left: 8px;
				padding-right: 8px;

				// &::before {
				// 	content: "";
				// 	position: absolute;
				// 	left: 0px;
				// 	top: 0px;
				// 	width: 1px;
				// 	height: 100%;
				// 	background: $border;
				// }

				.apt-dup {
					font-size: calc($font - 4px);
				}
			}

			&:last-of-type {
				margin-bottom: 0px;
				border-bottom: none;
			}

			&:before {
				content: "\ebea";
				font-family: "tabler-icons";
				display: inline-block;
				margin-left: 0px;
				color: $dark-grey;
				position: absolute;
				left: 0px;
				top: 0px;
			}
		}
	}
}

//apt-lastchnage

.apt-lastchnage {
	color: $primary;

	// padding: 10px 0px;
	ul {
		li {
			color: #1C2D3A;
			display: flex;
			align-items: center;
			font-weight: 500;
			margin-left: 8px;
			font-size: 15px;

			&::before {
				content: "•";
				font-size: 20px;
				padding-right: 10px;
				color: $dark-grey;
			}

			span {
				font-size: 12px;
				color: $primary;
				margin-left: 12px;
				font-size: 12px;

			}
		}
	}

	b {
		font-weight: 600;
	}

	.apt-info {
		font-size: 14px;
	}
}

.apt-footer {
	background: #F2F4F5;
	border-top: 1px solid #9BB8C4;
	border-radius: 0px 0px 4px 4px;
	padding: 16px;
	text-align: right;

	.btn {
		// min-width: 100px;
		text-align: center;
		font-size: calc($font - 2px);
		margin-left: 12px;


		&:first-of-type {
			margin-left: 0px;
		}

		&.btn-default {
			background: $default;
			color: $primary;
			border: 1px solid $border;
		}

	}
}

.apt-vform {
	padding: 10px;
	border: 1px solid $border;
	border-radius: 4px;
	height: calc(100vh - 324px);
	overflow-y: auto;
	overflow-x: hidden;
	margin-bottom: 0px;

	h5 {
		font-size: calc($font - 2px);
		color: $primary;
	}

	.form-group {
		position: relative;

		.apt-fnamehisty {
			position: absolute;
			right: 0px;
			top: 0px;
			color: $primary;
			font-size: calc($font - 4px);
		}
	}
}

.apt-gridlisth {
	display: none;

	&.btn-group {

		.btn-primary {
			line-height: 20px !important;
			height: 30px;
			color: #D3DCE2;
			border-color: #E5E9EC;
			background: $default;

			&.active {
				background: #E5E9EC;
				color: $dark-blue;
				border-color: #E5E9EC;
			}
		}

	}


}

.toggle-checkbox {
	display: none;

	&:checked+.toggle-label {
		.toggle-inner {
			margin-left: 0;
		}

		.toggle-switch {
			right: 0px;
		}
	}
}

.toggle-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border-radius: 20px;
}

.toggle {
	position: relative;
	width: 42px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}


.toggle-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	-moz-transition: margin 0.3s ease-in 0s;
	-webkit-transition: margin 0.3s ease-in 0s;
	-o-transition: margin 0.3s ease-in 0s;
	transition: margin 0.3s ease-in 0s;

	&:before {
		display: block;
		float: left;
		width: 50%;
		height: 22px;
		padding: 0;
		line-height: 30px;
		font-size: 16px;
		box-sizing: border-box;
		content: "\e92b";
		font-family: "Feather";
		padding-left: 4.5px;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		background-color: #0761cec4;
		color: $default;
		display: flex;
		align-items: center;
	}

	&:after {
		display: block;
		float: left;
		padding: 0;
		line-height: 30px;
		font-size: 18px;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		background-color: #7d7b7b61;
		color: $default;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 50%;
		height: 22px;
		padding: 0;
		line-height: 30px;
		font-size: 16px;
		box-sizing: border-box;
		content: "\ea02";
		font-family: "Feather";
		padding-right: 4.5px;
	}
}

.toggle-switch {
	display: block;
	width: 10px;
	margin: 6px;
	background: $default;
	border-radius: 80px;
	position: absolute;
	top: 0;
	bottom: 0;
	-moz-transition: all 0.3s ease-in 0s;
	-webkit-transition: all 0.3s ease-in 0s;
	-o-transition: all 0.3s ease-in 0s;
	transition: all 0.3s ease-in 0s;
}


///prime ng -table start///

.p-datatable .p-sortable-column:not(.p-highlight):hover {
	background: #d9e9ff;
	color: #495057;
}

.p-datatable {
	font-family: 'IBM Plex Sans';
}

.p-datatable .p-datatable-thead>tr>th:first-of-type {
	border-top-left-radius: 3px;
}

.p-datatable .p-datatable-thead>tr>th {
	color: #1C2D3A;
	font-size: 15px;
	font-weight: 600;
	padding: 15px 10px;
	border-bottom: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 80px;
	border-top: 0px;
	position: relative;
	background: #73a1ff17;

}

.p-datatable .p-datatable-tbody>tr>td {

	// padding: 12px 10px;
	color: $light-grey;
	border-top: 0px;
	font-size: 15px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	max-width: 200px;
	font-weight: 500;


	&:first-of-type {
		max-width: 250px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&.apt-table-td-per {

		.badge-dark {
			color: #1C2D3A;
			border-color: #f8f9fa;
			background-color: transparent;
			border: 1px solid #e9ecef;
			cursor: pointer;
			padding: 6px 8px;
		}

		max-width: 100px;

		span {
			&.badge-primary {
				color: #1C2D3A;
				background-color: #E7EFFD;
				border: 1px solid #B7C9EA;
				padding: .3rem .7rem;
				font-size: 10px;
				font-weight: 600;
				margin-right: 12px;
				margin-top: 6px;
				margin-bottom: 6px;

			}
		}
	}

	&.apt-table-td-icon {

		// float: right;
		i {
			font-size: 18px;
		}

		.apt-table-edit {
			color: $primary;
		}

		.apt-table-user {
			color: #147114;
		}

		.apt-table-user-2 {
			color: #D82D34;
		}
	}

	&.apt-status-active {
		.badge-primary {
			color: #39CA84;
			background: #3aca841a;
			padding: 6px 10px;
			font-size: 12px;
			font-weight: 600;
		}
	}

	&.no-data-found {
		border-bottom: 0px;

		span {
			color: $primary-btnf;
			font-weight: 600;
		}
	}

	&.apt-status-inactive {
		.badge-primary {
			color: #CF2B1F;
			background: #cf2b1f14;
			padding: 6px 10px;
			font-size: 12px;
			font-weight: 600;
		}
	}

}

.p-datatable .p-sortable-column {
	.p-icon-wrapper {

		// position: relative;
		// &::before {
		// 		content: "\e92e";
		// 		font-family: "Feather" !important;
		// 		color: $light-grey;
		// 		font-size: calc($font - 1px);
		// 		position: absolute;
		// 		right: 7px;
		// 		top: 19px;
		// 		padding-left: 10px;

		// 	}

		&::after {
			content: "\eb1d";
			font-family: "tabler-icons" !important;
			color: $light-grey;
			font-size: calc($font + 2px);
			position: absolute;
			right: 7px;
			padding-left: 10px;
			top: 50%;
			transform: translateY(-50%);

		}

	}



}

.p-datatable [aria-sort="ascending"] {
	.p-icon-wrapper {
		&::after {
			content: "\ea62";

		}

	}
}

.p-datatable [aria-sort="descending"] {
	.p-icon-wrapper {
		&::after {
			content: "\ea5f";

		}

	}
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
	display: none;
}


p-paginator {
	position: sticky !important;

}


.p-paginator {
	// background: #F5F6F8;
	color: #6c757d;
	border: 1px solid #9AB8C2;
	padding: 2px 15px;
	color: $dark-blue;
	border-left: 0px;
	border-right: 0px;
	font-size: 14px;
	justify-content: end !important;
	position: sticky;
	// bottom: -1px;
	border-bottom: 0px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background: #73a1ff17;
}

p-dropdown {
	display: none;
}

.p-paginator .p-paginator-current {
	// display: none;
}

.p-paginator {
	.p-paginator-pages {
		.p-paginator-page {
			font-size: 14px;

			&.p-highlight {
				background: $primary;
				border-color: #E3F2FD;
				color: $default;
				border-radius: 100%;
				font-size: 14px;
			}
		}
	}

	// .p-paginator-first {
	// 	display: none;
	// }

	// .p-paginator-last {
	// 	display: none;
	// }
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background-color: #eceff1;
	width: 4px;
}




.pagination {
	li {
		&.page-item {
			.page-link {
				position: relative;
				display: block;
				font-size: 14px;
				padding: 3px;
				min-width: 30px;
				height: 30px;
				border-radius: 4px !important;
				margin: 0px 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: -1px;
				line-height: 25px;
				color: $primary;
				background-color: $default;
				border: 1px solid $border;
			}

			&.active {
				.page-link {
					background-color: $primary;
					border-color: $primary;
					color: $default;
				}
			}
		}
	}
}

.theme-green .bs-datepicker-head {
	background-color: $primary;
}

.theme-green .bs-datepicker-body table td span.selected {
	background-color: $primary;
}

.detail-back {
	position: absolute;
	left: -12px;
	top: 88px;
	background: $default;
	border: 1px solid $border;
	z-index: 1;
	border-radius: 50%;
	width: 30px;
	height: 30px !important;
	padding: 0px !important;
	text-align: center;

	&:hover {
		border: 1px solid $primary;

	}
}

.apt-modal {
	background: $default;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border: 1px solid $border;
	// padding: 16px;
	border-radius: 4px;
	z-index: 99;
	min-width: 340px;
	display: none;
	z-index: 9999999;

	&.verification-modal {
		min-width: calc(100% - 0px);
	}


	.close {
		// color: #D8503F;
		// border: 1px solid #d8503f;
		// font-size: 14px;
		padding: 2px;
		border-radius: 50%;
		position: absolute;
		top: 10px;
		right: 12px;
		z-index: 1;
		opacity: 1;

		&::after {
			content: "\ea03";
			font-family: "Feather" !important;
			color: #F75151;
			font-size: 20px;
		}

	}

	h4 {
		font-size: calc($font - 0px);
		margin-bottom: 24px;
	}

	// .btn-default {
	// 	margin-right: 12px;
	// }

}

.apt-modal-overlay {
	z-index: 9;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	width: 100%;
	height: 100%;
	display: none;
}

.apt-modal-open {
	display: block;
}

.apt-modal-overlay-open {
	display: block;
}

.apt-modal-c {
	.modal-header {
		position: relative;
		color: $primary;
		font-size: 18px;
		padding: 16px;
		border-bottom: 1px solid $border;
		// &::after{
		//     content: "";
		//     background-color: #dadbde;
		//     width: 107%;
		//     height: 1px;
		//     left: -16px;
		//     position: absolute;
		//     right: 0px;
		//     bottom: -14px;
		// }
	}

	.modal-body {
		padding: 16px;

		&.apt-createy {
			height: calc(100vh - 226px);
			overflow-y: auto;
		}
	}

	.modal-footer {
		padding: 16px;
	}
}

.tooltip {
	.tooltip-inner {
		background-color: $primary;
		font-size: 12px;
	}

	&.tooltip-right .arrow::before {
		border-right-color: $primary;
	}

	&.tooltip-left .arrow::before {
		border-left-color: $primary;
	}

	&.tooltip-top .arrow::before {
		border-top-color: $primary;
	}

	&.tooltip-bottom .arrow::before {
		border-bottom-color: $primary;
	}
}


.popover {
	background: #7d83a4;
	color: $default;

	&.bs-popover-right>.arrow::after {
		border-right-color: #7d83a4;
	}

	.popover-body {
		min-width: 160px;

		ul {
			li {
				color: $default;
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin-bottom: 0px;

			li {
				padding-left: 20px;
				position: relative;
				margin-bottom: 8px;
				padding-bottom: 8px;
				border-bottom: 1px dashed rgba(255, 255, 255, 0.2);

				&:last-of-type {
					margin-bottom: 0px;
					padding-bottom: 0px;
					border-bottom: none;
				}

				&:before {
					content: "\f698";
					font-family: "tabler-icons";
					display: inline-block;
					margin-left: 0px;
					color: $default;
					position: absolute;
					left: 0px;
					top: 0px;
				}
			}
		}


	}
}

// Loader for Input control
.input-loader {
	position: relative;

	.spinner-border {
		position: absolute;
		width: 16px;
		height: 16px;
		border-width: 2px;
		color: $secondary;
		top: 10px;
		right: 10px;
	}

	.form-control {
		padding-right: 35px !important;
	}
}

.verification-modal {
	animation-duration: 1s;
	animation-fill-mode: both;
	bottom: inherit;
	transform: inherit;
	top: 0px;
	opacity: 0;
	animation: fadeIn 0.3s ease-in both;
	left: 0px;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInBottom {
	from {
		opacity: 0;
		transform: translateY(100%);
	}

	to {
		opacity: 1
	}
}

.apt-seloader {
	position: absolute;
	right: 88px;
	top: 9.5px;
	// transform: translate(0%, -50%);
	z-index: 9;

	.loader {
		border: 2px solid #B7C8EA;
		border-radius: 50%;
		border-top: 2px solid $primary;
		width: 20px;
		height: 20px;
		display: inline-block;
		-webkit-animation: spin 2s linear infinite;
		/* Safari */
		animation: spin 2s linear infinite;
	}

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

}


.apt-loader-c {
	position: absolute;
	background: rgba(255, 255, 255, 0.76);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999999;
	left: 0px;
	top: 0px;
}

.apt-asde {
	background: $default;
	cursor: pointer;
	padding: 5px 8px;
	/* border-right: 1px solid; */
	border: 1px solid rgba(219, 230, 235, 0.5);
	margin-left: 4px;
	border-radius: 4px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	color: $dark-blue;

	&.sort-ascending {
		i {
			&::before {
				content: "\ef18";
			}
		}
	}

	&.sort-descending {
		i {
			&::before {
				content: "\ef1a";
			}
		}
	}

}

.apt-mapinner {
	border-radius: 4px;
	padding: 4px;
	background: #dae6e9;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.051);
	border: 1px solid #ced6e7;
	height: calc(100vh - 89px);
	width: 100%;
	display: inline-block;
}

.filter-added {

	&:after {
		content: " ";
		position: absolute;
		left: 50%;
		bottom: -13px;
		border-top: none;
		border-right: 12px solid transparent;
		border-left: 12px solid transparent;
		border-bottom: 12px solid $border;
		transform: translate(-50%, 0%);
	}

}

.apt-litable {

	.apt-litbody {


		.panel-group {
			.panel-default {
				border-radius: 0px;
				border: none;


				// border-bottom: none;
				.panel-heading {
					background: none;
					padding: 0px;
					border-radius: 0px;
					border: none;

					&:after {
						position: absolute;
						right: 32px;
						top: 4px;
						color: #7d8689;
						content: "\e932";
						font-family: "feather";
						font-size: 16px;
						font-weight: normal;
						transform: rotate(-180deg);
						visibility: hidden;
					}
				}

				.panel-body {
					padding: 10px;
					background: $default;
				}


			}


			.panel-open {
				border: 1px solid rgba(45, 101, 216, 0.4);
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

				.panel-default {
					border-radius: 0px;

					.panel-heading {
						position: relative;

						&::before {
							content: "";
							width: 3px;
							height: calc(100% + 2px);
							background: #2d65d8;
							/* border-radius: 4px; */
							position: absolute;
							left: -2px;
							top: -1px;
							z-index: 99;
						}

						&:after {
							content: "\e932";
							top: 50%;
							visibility: visible;
							transform: translate(0%, -50%);
							animation: fadeInOut 3s ease-in-out infinite;
						}

						li {
							span {
								font-weight: 500;
								color: #000 !important;
							}
						}

					}

					// .panel-body {

					// }

					.panel-heading {
						background: rgba(227, 236, 255, 0.4);
						border-bottom: 1px solid #abbccd;
					}
				}
			}
		}

		accordion-group {
			margin: 8px 0px;
			border: 1px solid #e9ecef;

			&:hover {

				border: 1px solid rgba(45, 101, 216, 0.4);

				.card {
					background: rgba(227, 236, 255, 0.2);

					.panel-heading {
						position: relative;

						&:after {
							content: "\e934";
							top: 50%;
							visibility: visible;
							transform: translate(0%, -50%);
							animation: fadeInOut 3s ease-in-out infinite;
						}
					}

					.panel-body {
						background: $default;
					}

				}

			}

			&:last-of-type {
				margin-bottom: 0px;
			}
		}
	}
}


@keyframes fadeInOut {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.apt-pinner{
	position: relative;
}
.ng-dropdown-panel.apt-fl-drop {
	
	z-index: 99999;
}
.apt-complist{
	&.apt-flscreen {
		.apt-building-highlight {
			scale: 0.99 !important;
		}

		&.apt-complist {
			position: fixed;
			width: calc(100% - 30px);
			height: calc(100vh - 30px);
			left: 15px;
			top: 15px;
			z-index: 99999;
			border-radius: 4px;
		}
		
	
		.apt-bx {
			height: calc(100vh - 186px) !important;
	
			ul {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				margin-bottom: 0px;
				align-items: center;
	
				li {
					width: calc(25.1% - 15px);
					margin-right: 15px;
	
					&:nth-child(4n+4) {
						margin-right: 0px;
					}
				}
			}
	
		}
	
		.apt-litable {
			.apt-litbody {
				height: calc(100vh - 226px) !important;
			}
		}
		.apt-litable {
			.apt-blist-selection {
			    height: calc(100vh - 226px) !important;
			}
		}
	
		.apt-grid ul li .apt-provider {
			bottom: -42px !important;
		}
	
		.card.apt-listbx .card-body .apt-loc h3 {
			width: 260px;
	
		}
	
		.card.apt-listbx .card-body .apt-loc h5 p {
			width: 220px;
		}
	.apt-grid{
		accordion {
			display: inline-flex !important;
			flex-wrap: wrap;
	
			accordion-group {
				width: calc(33.6% - 15px);
				margin-right: 15px;
	
				.card {
					height: auto;
				}
	
			}
	
			accordion-group:nth-child(3n+3) {
				margin-right: 0px;
			}
	
	
		}
	}
		
	}
}


.apt-flscreen-overlay {
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
}

.apt-loc-vmain {
	padding: 16px;

	.apt-loc-verify {
		padding: 10px;
		background: #f6f9ff;
		border-radius: 4px;
		border: 1px solid #d9e8ff;

		h3 {
			font-size: calc($font - 2px);
			color: $dark-blue;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 220px;
		}

		h5 {
			font-size: calc($font - 2px);
			color: $dark-grey;
			margin: 0px;
			display: flex;
			align-items: center;

			span {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 220px;
				display: inline-block;
			}

			i {
				color: $dark-blue;
				margin-right: 8px;
			}

			p {
				font-size: calc($font - 2px);
			}
		}

		.apt-cinfo {

			h4 {
				font-size: calc($font - 2px);
				padding: 5px 0px;
				text-align: right;
				margin-bottom: 0px;

				span {
					color: $primary;
					margin-left: 5px;
				}
			}

		}

	}
}

// .apt-ver-sy{
//     overflow-y: auto;
//     height: calc(100vh - 261px);
//     padding: 16px;
//     padding-top: 0px;
// }


.apt-fres {
	google-map .map-container {
		width: 100% !important;
		height: calc(100vh - 146px) !important;
	}

	.apt-mapinner {
		height: calc(100vh - 136px);
	}

	.apt-grid {
		height: calc(100vh - 300px) !important;
	}

	.apt-litable .apt-litbody {
		margin-bottom: 0px;
		height: calc(100vh - 316px) !important;
		overflow-y: auto;
	}
	.apt-litable {
		.apt-blist-selection {
			height: calc(100vh - 336px) !important;
			
		}
	}

	// .apt-ver-sy {
	// 	height: calc(100vh - 308px);
	// }
	.apt-modal-c {
		.modal-body.apt-createy {
			height: calc(100vh - 273px);
		}
	}

	.marker-pop {
		.apt-modal-c {
			.modal-body.apt-createy {
				height: 250px;
			}
		}
	}

	.apt-flscreen {
		.apt-grid {
			height: calc(100vh - 170px) !important;
			&.apt-grid-selection {
				height: calc(100vh - 200px) !important;
			}
		}
		.apt-fl-chk {
			flex: 0 0 50%;
			max-width: 50%;
			display: flex;
			align-items: flex-end;
		}
		.apt-fl-title {
			flex: 0 0 50%;
			max-width: 50%;
			display: inline-flex !important;
			text-align: right;
			justify-content: end !important;
		}
		.apt-complist {
			.apt-comtitle {
				p {
					color: #7D8689;
					font-size: 14px;
					margin-right: 20px !important;
				}
			}
		}
		.t-switch {
			margin-bottom: 0px;
		}
	}
	
	// .apt-flscreen .apt-fl-title .apt-fl-count{

	// }
}

.main-card {

	border: none;

	.apt-lon-lat-timeline {
		width: 100%;
		position: relative;
		padding: 1.5rem 0 1rem;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0px;
			height: 100%;
			width: 2px;
			background: #eff2f9;
			border-radius: .25rem;
		}
	}

	.apt-lon-lat {
		position: relative;
		margin: 0 0 1rem;
	}

	.apt-lon-lat-timeline--animate {
		.apt-lon-lat-icon.bounce-in {
			visibility: visible;
			animation: cd-bounce-1 .8s;
		}
	}

	.apt-lon-lat-icon {
		position: absolute;
		top: 0;
		left: -11px;
		background: #f7f9ff;
		border: 1px solid #d9e7ff;
		border-radius: 4px;
		padding: 4px;
		line-height: 0px;
		color: $primary
	}

	.apt-lon-lat-content {

		&:after {
			content: "";
			display: table;
			clear: both;
		}

		position: relative;
		margin-left:30px;
		font-size: .8rem;


		.old-lat-lan {
			// display: inline-block;
			margin-bottom: 12px;
			position: relative;

			// .badge{
			// 		width: 60px;
			// 		position: absolute;
			// 		background: #ffa008;
			// 		color: #FFF;
			// 		border: none;
			// 		display: inline-block;
			// 		border-bottom-right-radius: 5px;
			// 		border-top-right-radius: 5px;
			// 		padding: 0px;
			// 		font-weight: 700;
			// 		cursor: pointer;
			// 		// &:before {
			// 		// 	content: " ";
			// 		// 	position: absolute;
			// 		// 	display: block;
			// 		// 	width: 100%;
			// 		// 	height: 100%;
			// 		// 	left: -51px;
			// 		// 	z-index: -1;
			// 		// 	background: #ffa008;
			// 		// 	transform-origin: bottom left;
			// 		// 	-ms-transform: skew(-28deg, 0deg);
			// 		// 	-webkit-transform: skew(-28deg, 0deg);
			// 		// 	transform: skew(-28deg, 0deg);
			// 		// }
			// }
			.input-group {
				.input-group-prepend {

					span {
						padding: 3px 8px;
						font-size: calc($font - 4px);
						background: #f7f9ff;
						border-color: #d9e7ff;

					}
				}
			}

			.form-control {
				height: auto;
				background: rgb(247 249 255 / 77%);
				border: 1px solid #e8eaf2;
				font-size: calc($font - 4px);
				padding: 3px 8px;
				font-weight: 500;
			}
		}

		.new-lat-lan {
			.input-group {
				.input-group-prepend {

					span {
						padding: 3px 8px;
						font-size: calc($font - 4px);
						background: #f7f9ff;
						border-color: #d9e7ff;
						border: none;
						border-bottom: 1px solid $border;
						border-radius: 0px;

					}
				}
			}

			.form-control {
				border: none;
				border-bottom: 1px solid $border;
				border-radius: 0px;
				padding-left: 8px;

			}
		}
	}
}


.apt-nlatlong {
	ul {
		padding-left: 0px;

		li {
			position: relative;

			h3 {
				color: #4B4B4B;
				text-transform: uppercase;
				font-size: 13px;
				font-weight: 500;

				i {
					color: $primary;
					font-size: 14px;
				}
			}
		}
	}

	.old-lat-lan {
		.form-control {
			background: #F8F8F8;
			border: 1px solid #DCE7FD;
			color: $dark-grey;
			border-bottom: none;
			border-radius: 4px;
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
			font-size: 14px;
			padding: 4px 8px;

			&::placeholder {
				font-weight: 400;
			}

		}
	}

	.new-lat-lan {
		.form-control {
			background: #F0FAF8;
			border: 1px solid #CAE3DB;
			color: #000;
			border-radius: 4px;
			border-top-right-radius: 0px;
			border-top-left-radius: 0px;
			font-size: 14px;
			font-weight: 600;
			padding: 4px 8px;

			&::placeholder {
				font-weight: 400;
			}
		}

	}

	.error-has {
		font-size: 14px;
		width: 100%;
		display: inline-block;
		text-align: center;
	}

	.level-3-wrapper {
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 66px;
		width: 100%;
		margin: 0 auto;

		.level-3 {
			margin-bottom: 12px;
		}
	}

	.level-4-wrapper {
		position: relative;
		width: 91.5%;
		margin-left: auto;

		&::before {
			content: "";
			position: absolute;
			top: -14px;
			left: -11px;
			width: 2px;
			height: calc(100% + -3px);
			background: #CCDDFF;
		}

		.level-4 {
			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 0%;
				transform: translate(-100%, -50%);
				width: 11px;
				height: 2px;
				background: #CCDDFF;
			}
		}
	}

	.badge-m {
		position: absolute;
		width: 67px;
		z-index: 99;
		top: 50%;
		transform: translateY(-50%);
		left: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&::before {
			content: "";
			display: inline-block;
			width: 100%;
			height: 2px;
			background: #CCDDFF;
			top: 50%;
			transform: translateY(-50%);

		}

		.badge {
			background: #F8F8F8;
			border: 1px solid #DCE7FD;
			padding: 5px 10px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		.new-lat-lanbb {
			background: #F8FFFD;
			border: 1px solid #CAE3DB;
			padding: 5px 10px;
		}
	}

	.apt-nlong {
		.level-4-wrapper {
			margin-left: initial;
			margin-right: auto;

			&::before {
				right: -11px;
				left: inherit;
			}

			.level-4 {
				&::before {
					right: 0px;
					left: inherit;
					transform: translate(100%, -50%);
				}
			}
		}

	}
}

.apt-grid {
	.panel-group {
		.panel {
			margin-bottom: 16px;
			border: none;
			border-radius: 4px;

			.panel-heading {
				padding: 0px;
				border: none;
				position: relative;

				&:after {
					position: absolute;
					right: 57px;
					top: 14px;
					color: #7d8689;
					content: "\e932";
					font-family: "feather";
					font-size: 16px;
					font-weight: normal;
					transform: rotate(-90deg);
					z-index: 2;
					visibility: hidden;
				}

				&:before {
					// content: "";
					// position: absolute;
					// right: 1px;
					// top: 1px;
					// width: 24px;
					// height: 24px;
					// display: inline-flex;
					// align-items: center;
					// justify-content: center;
					// background: #e5e9ec;
					// border-top-right-radius: 4px;
					// border-bottom-left-radius: 4px;
					// z-index: 1;
					// visibility: hidden;
				}
			}

			.panel-body {
				padding: 10px;
				border: 1px solid rgba(45, 101, 216, 0.4);
				border-top: none;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}

			&:last-of-type {
				margin-bottom: 0px;
			}

			&.panel-open {
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

				.apt-listbx {
					border-bottom-left-radius: 0px;
					border-bottom-right-radius: 0px;
				}

				.panel-heading {

					// .card{
					// 	border:1px solid rgba(45, 101, 216, 0.4);
					// }
					&:after {
						content: "\e932";
						transform: rotate(-180deg);
					}
				}
			}

			&:hover {
				.panel-heading {
					&::after {
						visibility: visible;
						animation: fadeInOut 3s ease-in-out infinite;
					}

					&::before {
						visibility: visible;

					}
				}
			}
		}

	}
}

@media screen and (max-width: 1920px) {
	.apt-flscreen.apt-grid {
		accordion {
			display: inline-flex !important;
			flex-wrap: wrap;

			accordion-group {
				width: calc(33.33% - 15px);

			}

		}
	}
}

.gm-fullscreen-control {
	display: none !important;
}

@media screen and (max-width: 3840px) {
	.apt-flscreen.apt-grid {
		accordion {
			display: inline-flex !important;
			flex-wrap: wrap;

			accordion-group {
				width: calc(33.33% - 15px);

			}

		}
	}
}



.apt-building-highlight {
	background: rgba(227, 236, 255, 0.4) !important;
	border: 1px solid #abbccd !important;

}

.apt-pagination {
	padding: 11px 16px;
	width: 100%;
	box-shadow: 0px -4px 2px -3px rgba(219, 230, 235, 0.66);

	.label {
		font-size: 14px;
	}

	.ng-select .ng-select-container {
		border: none;
	}
}

.ng-dropdown-panel {
	border: 1px solid $border;
}


::ng-deep {
	.apt-mapg {
		position: relative;

		.gm-style .gm-style-iw-tc::after {
			display: none !important;
		}

		.apt-modal-c {
			&:after {
				position: absolute;
				bottom: -8px;
				left: 50%;
				margin-left: -10px;
				content: "";
				display: block;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid #fff;
			}
		}
	}

	.custom-ng-select {
		overflow-x: hidden;
	}

	// .ng-wrapper{
	//     .ng-dropdown-panel .scroll-host{
	//         bottom: 0px !important;
	//         right: -61px !important;
	//         left: 50px !important;
	//         width: 399px !important;
	//     }
	// }
	// .apt-phaseinfo1{
	//     .ng-select {
	//         &.ng-select-single {
	//             .ng-select-container {
	//                 min-height: 28px;
	//                 cursor: pointer;
	//                 &:hover{
	//                     box-shadow: none;
	//                 }
	//                 .ng-arrow-wrapper {
	//                     .ng-arrow {
	//                         top: -7px !important;
	//                     }
	//                 }

	//             }
	//         }
	//     }

	// }
}


// .gm-style-iw-tc::after {
// 	display: none;
// }
// map 
// .apt-top:has(.apt-modal-c) {
// 	transform: translate3d(-50%, 0,0) !important;
// }
// .apt-left:has(.apt-modal-c) {
// 	transform: translate3d(4%,-20%,0) !important;
// }
// .apt-topleft:has(.apt-modal-c) {
// 	transform: translate3d(3%, -7%, 0) !important;
// }
// ::ng-deep {
// 	.apt-topleft + .gm-style-iw-tc::after {
// 		left: 20px;
// 		top: 15px;
// 		transform: rotate(90deg);
// 	}
// }

// .apt-right:has(.apt-modal-c) {
// 	transform: translate3d(-102%,-7%,0) !important;
// }
// .apt-topright:has(.apt-modal-c) {
// 	transform: translate3d(-102%,-7%,0) !important;
// }

// .apt-bottomleft:has(.apt-modal-c) {
// 	transform: translate3d(-10%,-100%,0) !important;
// }
// .apt-bottomright:has(.apt-modal-c) {
// 	transform: translate3d(-102%,-90%,0) !important;
// }



.progress {
	background-color: rgb(222 231 250);
}

.progress-bar {
	background-color: #2d65d8d1;
	// background-color: $primary;
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 1) 30%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

// .apt-prog{
// 	height: 0.35rem;
// 	position: absolute;
// 	left: -10px;
// 	top: -10px;
// 	width: calc(100% + 20px) !important;
// 	border-radius: 0px;
// }


.apt-report {
	.p-datatable {
		.p-datatable-tbody {
			>tr {
				>td {
					&:first-of-type {
						max-width: 150px;
					}

					&:nth-child(2) {
						max-width: 130px;
					}

					&:nth-child(6) {
						max-width: 100px;
					}
				}
			}
		}
	}
}


.apt-tags {
	width: 24px;
	height: 24px;
	background: rgba(45, 101, 216, 0.129);
	border-radius: 50%;
	margin-right: 8px;
	text-align: center;
	color: $primary;
	border: 1px solid;

	i {
		margin: 0px !important;
		padding: 0px !important;
	}

	.apt_tagslist {
		display: none;
		width: 230px;
		position: absolute;
		right: 34px;
		text-align: left;
		background: #646983;
		padding: 8px;
		border-radius: 4px;
		z-index: 99999;
		/* transform: translateY(-100%); */
		top: -4px;

		&:after {
			background: #646983;
			clip-path: polygon(0 0, 50% 100%, 100% 0);
			content: "";
			height: 10px;
			width: 18px;
			position: absolute;
			top: 14px;
			right: -13px;
			margin: 0 auto;
			transform: rotate(271deg);
		}

		ul {
			margin-bottom: 0px;

			li {
				font-size: 13px;
				color: $default;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 3px;
				margin-bottom: 3px;
				font-weight: 500;

				&:last-of-type {
					padding-bottom: 0px;
					margin-bottom: 0px;
					border-bottom: none;
				}
			}
		}
	}

	&:hover {
		.apt_tagslist {
			display: block;
		}


	}
}

// .apt-grid{
// 	accordion-group{
// 		&:first-of-type{
// 			.apt-tags:hover .apt_tagslist {
// 				z-index: 99999;
// 				transform: translateY(0%);
// 				top: 33px;
// 				&:after {
// 					transform: rotate(180deg);
// 					bottom: initial;
// 					top: -10px;
// 				}
// 			}
// 		}
// 	}
// }

.apt-poptop {
	accordion-group:nth-last-child(-n + 3) {
		.apt-tags .apt_tagslist {
			top: inherit;
			bottom: -4px;

			&::after {
				top: inherit;
				bottom: 14px;
			}
		}
	}
}

.p-toast-detail {
	white-space: pre-line;
}

.copy-btn{
	// visibility: hidden;
	border: none !important;
	background:none !important;
	color: $primary !important;
	padding: 0;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 8px;
	i{
		font-size:calc($font - 3px) !important;
	}
	&:active {
		box-shadow: none !important;
		color: #037f28 !important;
	}
	
}

.apt-highlight{
	box-shadow: 0px 0px 0px 5px #eadafa !important;
	scale:0.98;
 .apt-listbx{
	border: 1px solid #7f00ff !important;
	box-shadow: 0px 0px, inset 0 0 2px #eadafa;
	
	.card-body {
		.apt-loc{
			// h3 {
			// 	color: #630590;
			// }
			//  h5 {
			// 	font-size: 14px;
			// 	color: #975db4;
			// }

		}
		// .bu-counts{
		// 	span:first-of-type {
		// 		color: #553e60;
		// 	}
		// 	 .apt-icon {
		// 		color: #7c5d8b;
		// 		background: #fde7ff;
		// 	}

		// }
		.apt-loc.apt-complex-select::before {
			background: #ffffff;
			background: linear-gradient(to right, #ffffff 50%, #f8efff 100%);
		}
	
	
	}
	.btn.apt-bul-det {
		border: 1px solid #7f00ff;
		background: #7f00ff;
		color:#fff !important;
	}
	.select input[type=checkbox]:checked + label::before {
		
		border-color: transparent transparent #630590 #630590 !important;
	}
	&:hover .bu-count span:last-of-type {
		color: #630590 !important;
	}
 }
 .panel-body{
	border: 1px solid #7f00ff !important;
	border-top: none !important;
 }
}
.apt-litable .apt-litbody .panel-group{
	.apt-building-highlight {
		.panel-default .panel-heading::before {
			background: #630590 !important;
		}
		// &.panel-open .panel-default .panel-heading li span {
		// 	font-weight: 500;
		// 	color: #630590 !important;
		// }
	}

}

.apt-tags .apt_tagslist ul li{
width: 100% !important;
}



