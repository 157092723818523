$primary:#2d65d8;
$secondary:#0761CE;

$default:#fff;
$body-bg:#F2F4F5;
$body-txt:#000;
$border:#DBE6EB;

$dark:#4A4A4A;
$light-dark:#374957;
$light-grey:#565D6A;
$light-blue:#5F7D95;

$primary-btnf:#132641;
$default-btnf:#7F8C9F;

$font: 16px;
$form-control-het: 42px;
$form-control-border:#DADBDE;
$form-label-color: #A0A5AA; 

$light-grey-n:#ECEFF1;



$dark-blue:#0D263C;
$dark-grey:#7C7C7C;

$light-red:#F4938F;
$dark-red:#F0655F;
$dark-danger:#dc3545;
$light-danger:#f8594d;
$light-bg:#fde4e1;
$light-border:#ffa7a1;


$light-green:#249F2C;
$dark-green:#279F2C;

$light-viloet:#6D5FD6;
$dark-viloet:#806FFF;
$light-gtone:#3f8cf2;


$light-cyan:#0285b5;
$light-yellow:#ffa032;

$map-highlight-color: #C34CFC;

$scrollbar-thumb-width: 6px;
$scrollbar-thumb-color: #c4cdd5;
$scrollbar-track-color: #f4f6f9;

@mixin map-arrow {
    &:after {
        background: #7f00ff;
        -webkit-clip-path: polygon(0 0,50% 100%,100% 0);
        clip-path: polygon(0 0,50% 100%,100% 0);
        content: "";
        height: 13px;
        width: 25px;
        position: absolute;
        left: 0;
        bottom: -17px;
        right: 0;
        margin: 0 auto;
    }
}
@mixin stats-set {
    ul {
        margin-bottom: 0;
        li {
            float: left;
            padding: 0 15px;
            position: relative;
            font-size: 14px;
            font-weight: 500;
            color: #7e8ba6;
            span {
                font-weight: 600;
                padding-left: 3px;
            }
            &:nth-of-type(1) {
                padding-left: 0;
                span {
                    color: #2d65d8;
                }
            }
            &:nth-of-type(2) {
                span {
                    color: #0285b5;
                }
            }
            &:nth-of-type(3) {
                span {
                    color: #249f2c;
                }
            }
            &:nth-last-of-type(1) {
                padding-right: 0;
                &::after {
                    display: none;
                }
                span {
                    color: #f0655f;
                }
            }
            &::after {
                content: "";
                height: 15px;
                width: 1px;
                background: #a5bbea;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0,-50%);
            }
        }
    }
    &.fixed {
        position: fixed;
        top: 155px;
        right: 0;
        z-index: 999;
        background: #fff;
        box-shadow: 0px 0px 2px rgba(26, 57, 120, 0.43);
        border-radius: 4px 0 0 4px;
        border: 1px solid $border;
        border-right: 0;
        display: inline-block;
        float: inherit;
        padding: 0;
        ul {
            margin-bottom: 0;
            li {
                padding: 5px 10px;
                border-bottom: 1px solid $border;
                background: linear-gradient(to right, rgb(187 210 255) 0%, rgba(246, 246, 246, 0.52) 48%, rgba(255, 255, 255, 0) 100%);
                font-size: 15px;
                font-weight: 500;
                &:nth-of-type(1) {
                    background: linear-gradient(to right, rgb(206 221 251) 0%, rgba(246, 246, 246, 0.52) 48%, rgba(255, 255, 255, 0) 100%);
                    color: #406ac2;
                }
                &:nth-of-type(2) {
                    background: linear-gradient(to right, rgb(132 201 226) 0%, rgba(246, 246, 246, 0.52) 48%, rgba(255, 255, 255, 0) 100%);
                    color: #0285b5;
                }
                &:nth-of-type(3) {
                    background: linear-gradient(to right, rgb(165 217 169) 0%, rgba(246, 246, 246, 0.52) 48%, rgba(255, 255, 255, 0) 100%);
                    color: #3eb844;
                }
                &:nth-last-of-type(1) {
                    border-bottom: 0;
                    background: linear-gradient(to right, rgb(251 214 212) 0%, rgba(246, 246, 246, 0.52) 48%, rgba(255, 255, 255, 0) 100%);
                    color: #f0655f;
                }
                span {
                    font-weight: 600;
                    color: #000;
                }
            }
        }
    }
}